<template>
  <base-selector-autocomplete
    :items="items"
    :initial-value="initialValue"
    :item-text="'api_key'"
    item-value="_id"
    label="Select clients"
    @select="newSelect => $emit('select', newSelect, 'client')"
  />
</template>

<script>
  import BaseSelectorAutocomplete from '@/components/base/selectors/BaseSelectorAutocomplete';
  import { mapGetters } from 'vuex';

  export default {
    name: 'BaseClientsSelector',
    components: {
      BaseSelectorAutocomplete,
    },
    props: {
      initialValue: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters({
        apolloClients: 'apollo/apolloClients',
      }),
      items () {
        const items = [{
          api_key: 'all clients',
          _id: '',
        }];
        this.apolloClients.map(client => {
          items.push({
            api_key: client.api_key,
            _id: client._id,
          });
        });
        return items;
      },
    },

  };
</script>

<style scoped>

</style>
